<template>
  <div class="component-cashflow-quadrant-table-period">
    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col cols="8">
                  <h2>{{ $t(translationPath + 'title_values', { start_date: startDate, end_date: endDate }) }}</h2>
                </b-col>
                <b-col cols="4" class="text-right">
                  <!-- Toggle columns component here -->
                  <cashflow-quadrant-toggle-columns
                    :options="toggleColumnsOptions"
                    @column-to-toggle-emit="onColumnToggle"
                  />

                  <!-- Toggle result charts -->
                  <b-dropdown right
                              :text="$t('common.toggle_chart')"
                              variant='outline-secondary'
                              class="float-right mr-3 toggle-dropdown-item-wrapper"
                              size="sm"
                  >
                    <b-dropdown-item @click="toggleChart('bar-chart')" v-bind:class="{ active: (showChart === 'bar-chart') }">{{$t( translationPath + 'charts.bar_chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('area-chart')" v-bind:class="{ active: (showChart === 'area-chart') }">{{$t(translationPath + 'charts.area_chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('area-percentage-chart')" v-bind:class="{ active: (showChart === 'area-percentage-chart') }">{{$t(translationPath + 'charts.area_percentage_chart')}}</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </template>
            <div class="col-lg-8 pl-0">
              <p>{{ $t(translationPath + 'description_values') }}</p>
            </div>

            <b-table
              class="spirecta-simple-table cashflow-quadrant-table mb-3"
              show-empty
              hover
              responsive
              striped
              stacked="md"
              :items="tableData"
              :fields="tableColumns"
              :busy="!isLoaded"
              :tbodyTrClass="trClass"
            >
              <template v-slot:table-busy><loader/></template>

              <!-- title slot-->
              <template v-slot:cell(title)="row">
                <template v-if="row.item.row_type === 'empty'">{{ $t(row.field.label) }}</template>
                <template v-else>{{ $t(row.value) }}</template>
              </template>

              <!-- Period slots: HEAD -->
              <template v-slot:[gomyhead(sPeriod)]="row" v-for="sPeriod in this.apiData ? this.apiData.meta.periods : []">
                <template v-if="resolution === 'month' && Object.prototype.hasOwnProperty.call(row.field, 'meta')">
                  {{ $t('common.months.' + months[row.field.meta.month]) }} <span v-if="!row.field.meta.month" :key="sPeriod">{{ row.field.meta.year }}</span>
                </template>
                <template v-else>
                  {{ row.field.label }}
                </template>
              </template>

              <!-- Period slots: BODY -->
              <template v-slot:[gomycell(sPeriod)]="row" v-for="sPeriod in this.apiData ? this.apiData.meta.periods : []">
                <template v-if="row.item.row_type === 'empty'">
                  <template v-if="resolution === 'month'">
                    {{ $t('common.months.' + months[row.field.meta.month]) }}
                  </template>
                  <template  v-else>
                    {{ row.field.label }}
                  </template>
                </template>
                <template v-else>
                  {{ amountFormatter(row.value) }}
                </template>
              </template>

              <!-- total slot-->
              <template v-slot:head(total)="row">
                {{ $t(row.field.label) }}
              </template>
              <template v-slot:cell(total)="row">
                <template v-if="row.item.row_type === 'empty'">{{ $t(row.field.label) }}</template>
                <template v-else>{{ amountFormatter(row.value) }}</template>
              </template>

              <!-- average slot-->
              <template v-slot:head(average)="row">
                {{ $t(row.field.label) }}
              </template>
              <template v-slot:cell(average)="row">
                <template v-if="row.item.row_type === 'empty'">{{ $t(row.field.label) }}</template>
                <template v-else>{{ amountFormatter(row.value) }}</template>
              </template>

              <!-- median slot-->
              <template v-slot:head(median)="row">
                {{ $t(row.field.label) }}
              </template>
              <template v-slot:cell(median)="row">
                <template v-if="row.item.row_type === 'empty'">{{ $t(row.field.label) }}</template>
                <template v-else>{{ amountFormatter(row.value) }}</template>
              </template>

              <!-- min slot-->
              <template v-slot:head(min)="row">
                {{ $t(row.field.label) }}
              </template>
              <template v-slot:cell(min)="row">
                <template v-if="row.item.row_type === 'empty'">{{ $t(row.field.label) }}</template>
                <template v-else>{{ amountFormatter(row.value) }}</template>
              </template>

              <!-- max slot-->
              <template v-slot:head(max)="row">
                {{ $t(row.field.label) }}
              </template>
              <template v-slot:cell(max)="row">
                <template v-if="row.item.row_type === 'empty'">{{ $t(row.field.label) }}</template>
                <template v-else>{{ amountFormatter(row.value) }}</template>
              </template>

              <!-- END CUSTOM SLOTS -->
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader'
import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'
import CashflowQuadrantToggleColumns from './../inc/CashflowQuadrantToggleColumns'

export default {
  name: 'CashflowQuadrantTablePeriod',
  components: { Loader, CashflowQuadrantToggleColumns },
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    resolution: { type: String },
    activeChartOption: { type: String, default: 'bar-chart' },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      showChart: 'bar-chart',
      translationPath: 'reports.performance.cashflow_quadrant.table.',
      tableData: [],
      tableColumns: [],
      months: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
      toggleColumnsOptions: {
        total: true,
        average: true,
        median: false,
        min: false,
        max: false
      }
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    toggleChart (showChart) {
      this.showChart = showChart
      this.$emit('chart-to-display', { from_tab: 'summary', chart: showChart })
    },
    buildTableDataRows () {
      const data = {
        employee_amounts: { title: 'common.cashflow_quadrant.employee', total: 0, average: 0, median: 0, min: 0, max: 0, row_type: 'quadrant' },
        investor_amounts: { title: 'common.cashflow_quadrant.investor', total: 0, average: 0, median: 0, min: 0, max: 0, row_type: 'quadrant' },
        self_employed_amounts: { title: 'common.cashflow_quadrant.self_employed', total: 0, average: 0, median: 0, min: 0, max: 0, row_type: 'quadrant' },
        business_owner_amounts: { title: 'common.cashflow_quadrant.business_owner', total: 0, average: 0, median: 0, min: 0, max: 0, row_type: 'quadrant' },

        employee_percentages: { title: 'common.cashflow_quadrant.employee', total: 0, average: 0, median: 0, min: 0, max: 0, row_type: 'quadrant' },
        investor_percentages: { title: 'common.cashflow_quadrant.investor', total: 0, average: 0, median: 0, min: 0, max: 0, row_type: 'quadrant' },
        self_employed_percentages: { title: 'common.cashflow_quadrant.self_employed', total: 0, average: 0, median: 0, min: 0, max: 0, row_type: 'quadrant' },
        business_owner_percentages: { title: 'common.cashflow_quadrant.business_owner', total: 0, average: 0, median: 0, min: 0, max: 0, row_type: 'quadrant' }
      }
      const sumRowAmount = { row_type: 'total' }
      const sumRowPercentages = { row_type: 'total' }

      if (this.apiData) {
        for (const quadrant in this.apiData.quadrants) {
          data[quadrant + '_amounts'].total = this.apiData.quadrants[quadrant].total_amounts.sum
          data[quadrant + '_amounts'].average = this.apiData.quadrants[quadrant].total_amounts.average
          data[quadrant + '_amounts'].median = this.apiData.quadrants[quadrant].total_amounts.median
          data[quadrant + '_amounts'].min = this.apiData.quadrants[quadrant].total_amounts.min
          data[quadrant + '_amounts'].max = this.apiData.quadrants[quadrant].total_amounts.max

          data[quadrant + '_percentages'].total = '--'
          data[quadrant + '_percentages'].average = this.apiData.quadrants[quadrant].total_percentages.average.toFixed(2) + ' %'
          data[quadrant + '_percentages'].median = this.apiData.quadrants[quadrant].total_percentages.median.toFixed(2) + ' %'
          data[quadrant + '_percentages'].min = this.apiData.quadrants[quadrant].total_percentages.min.toFixed(2) + ' %'
          data[quadrant + '_percentages'].max = this.apiData.quadrants[quadrant].total_percentages.max.toFixed(2) + ' %'

          for (const period in this.apiData.quadrants[quadrant].period_amounts) {
            data[quadrant + '_amounts'][period] = this.apiData.quadrants[quadrant].period_amounts[period]
            data[quadrant + '_percentages'][period] = this.apiData.quadrants[quadrant].period_percentages[period] + ' %'
          }
        }

        sumRowAmount.title = 'common.sum'
        sumRowAmount.total = this.apiData.totals.amounts.sum
        sumRowAmount.average = this.apiData.totals.amounts.average
        sumRowAmount.median = this.apiData.totals.amounts.median
        sumRowAmount.min = this.apiData.totals.amounts.min
        sumRowAmount.max = this.apiData.totals.amounts.max

        sumRowPercentages.title = 'common.sum'
        sumRowPercentages.total = '--'
        sumRowPercentages.average = this.apiData.totals.percentages.average
        sumRowPercentages.median = this.apiData.totals.percentages.median
        sumRowPercentages.min = this.apiData.totals.percentages.min
        sumRowPercentages.max = this.apiData.totals.percentages.max

        for (const period in this.apiData.totals.period_amounts) {
          sumRowAmount[period] = this.apiData.totals.period_amounts[period]
          sumRowPercentages[period] = this.apiData.totals.period_percentages[period] + ' %'
        }
      }

      const emptyRow = { title: this.$t(this.translationPath + 'th_types'), row_type: 'empty' }

      const rows = [
        data.employee_amounts, data.self_employed_amounts, data.business_owner_amounts, data.investor_amounts, sumRowAmount, emptyRow,
        data.employee_percentages, data.self_employed_percentages, data.business_owner_percentages, data.investor_percentages /*, sumRowPercentages */
      ]
      return rows
    },
    buildTableColumns () {
      const cols = [{ key: 'title', label: this.$t(this.translationPath + 'th_quadrant') }]

      if (this.apiData) {
        this.apiData.meta.periods.map(period => {
          const col = { key: String(period), label: period, class: 'text-right td-period' }

          if (this.resolution === 'month') {
            const ym = period.split('-')
            col.meta = { year: Number(ym[0]), month: Number(ym[1] - 1) }
          }
          cols.push(col)
        })
      }

      let isTotalColumnUsed = false
      for (const option in this.toggleColumnsOptions) {
        if (this.toggleColumnsOptions[option]) {
          const col = { key: option, label: 'common.' + option, class: 'text-right td-' + option }
          if (!isTotalColumnUsed) {
            col.class = 'td-total-column text-right'
            isTotalColumnUsed = true
          }
          cols.push(col)
        }
      }
      return cols
    },
    onColumnToggle (column) {
      this.toggleColumnsOptions[column] = !this.toggleColumnsOptions[column]
      this.tableColumns = this.buildTableColumns()
    },
    gomycell (key) {
      return `cell(${key})`
    },
    gomyhead (key) {
      return `head(${key})`
    },
    amountFormatter (value) {
      return this.currentCOA.locale ? formatAmount(value, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, false, 0) : Math.ceil(value)
    },
    trClass (item) {
      if (!item) {
        return ''
      }
      if (Object.prototype.hasOwnProperty.call(item, 'row_type')) {
        return 'tr-' + item.row_type.replace(/_/, '-')
      }

      return ''
    }
  },
  watch: {
    apiData: {
      handler: function () {
        this.tableColumns = this.buildTableColumns()
        this.tableData = this.buildTableDataRows()
      },
      deep: true,
      immediate: true
    },
    activeChartOption (v) {
      this.showChart = v
    }
  }
}
</script>

<style lang="scss">
.component-cashflow-quadrant-table-period{
    tr.tr-empty td{
      background: white;
      padding-top:30px;
      font-weight: bold;
    }
}
</style>
