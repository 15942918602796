<template>
  <b-container fluid class="component-reports-cashflow-quadrant-multi-series-pie-chart">
    <pie-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'reports-cashflow-quadrant-multi-series-pie-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </pie-chart>
  </b-container>
</template>

<script>
import PieChart from '@/components/chartjs/DoughnutChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import chartPalettes from '@/mixins/defaultChartColorPalettes'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'CashflowQuadrantMultiSeriesPieChart',
  components: { PieChart },
  mixins: [chartOptions, chartPalettes],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.cashflow_quadrant.chart.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }

      const aCashflowQuadrantLabels = []
      const aTmpCashflowQuadrantCategories = { employee: [], self_employed: [], business_owner: [], investor: [] }
      const currencySymbol = this.currencySymbol ? this.currencySymbol : 'kr'
      const oDatasetAccounts = { data: [], backgroundColor: [] }
      const oDatasetQuadrants = { data: [], backgroundColor: [] }
      let oPalette = this.getChartPalette()

      /* Modify the Y-Axis */
      this.chartOptions.scales.yAxes[0].display = false
      this.chartOptions.scales.xAxes[0].display = false
      this.chartOptions.legend.display = true

      /* Make half circle */
      this.chartOptions.rotation = -Math.PI
      this.chartOptions.circumference = Math.PI

      /* Tooltip */
      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, chartData) {
        const fAmount = chartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
        let iLabelIndex = tooltipItem.index
        if (tooltipItem.datasetIndex === 1) {
          iLabelIndex += chartData.datasets[0].data.length
        }
        const sTitle = chartData.labels[iLabelIndex]

        return sTitle + ': ' + formatNumberToFull(fAmount) + ' ' + currencySymbol
      }

      /**
       * Build dataset
       */
      // Step 1. Prepare for sorting by merging into one array
      let sTmpColor = null
      for (const sQuadrant in this.apiData.accounts) {
        sTmpColor = oPalette.shift()
        for (const iAccountId in this.apiData.accounts[sQuadrant]) {
          if (this.apiData.accounts[sQuadrant][iAccountId].sum !== 0) {
            aTmpCashflowQuadrantCategories[sQuadrant].push({
              title: this.apiData.accounts[sQuadrant][iAccountId].label,
              amount: this.apiData.accounts[sQuadrant][iAccountId].sum,
              color: sTmpColor
            })
          }
        }
      }

      // Step 2. Sort according to amount for better charts
      for (const sQuadrant in this.apiData.accounts) {
        aTmpCashflowQuadrantCategories[sQuadrant].sort(function cmp (a, b) {
          return b.amount - a.amount
        })
      }

      // Step 3. Add to dataset (outer dataset)
      for (const sQuadrant in aTmpCashflowQuadrantCategories) {
        for (const idx in aTmpCashflowQuadrantCategories[sQuadrant]) {
          aCashflowQuadrantLabels.push(aTmpCashflowQuadrantCategories[sQuadrant][idx].title)
          oDatasetAccounts.data.push(aTmpCashflowQuadrantCategories[sQuadrant][idx].amount)
          oDatasetAccounts.backgroundColor.push(aTmpCashflowQuadrantCategories[sQuadrant][idx].color)
        }
      }

      // Step 4. Add risk_scales (inner dataset)
      oPalette = this.getChartPalette()
      for (const sQuadrant in this.apiData.accounts) {
        oDatasetQuadrants.data.push(this.apiData.quadrants[sQuadrant].total_amounts.sum)
        oDatasetQuadrants.backgroundColor.push(oPalette.shift())

        if (sQuadrant !== 'not_set') {
          aCashflowQuadrantLabels.push(this.$t('common.cashflow_quadrant.' + sQuadrant))
        } else if (this.apiData.meta.is_uncategorized_non_zero && sQuadrant === 'not_set') {
          aCashflowQuadrantLabels.push(this.$t('common.cashflow_quadrant.' + sQuadrant))
        }
      }

      /* Prepare data for chart */
      this.chartData = {
        labels: aCashflowQuadrantLabels,
        datasets: [oDatasetAccounts, oDatasetQuadrants]
      }
    }
  },
  watch: {
    data () {
      this.fillChart()
    },
    options: {
      deep: true,
      handler () {
        this.fillChart()
      }
    }
  },
  created () {
    this.fillChart()
  }
}
</script>
