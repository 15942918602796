<template>
  <div class="component-cashflow-quadrant-list-of-assets-table">
    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col>
                  <h2>{{ $t(translationPath + 'title_list_of_income_categories_table', { start_date: startDate, end_date: endDate }) }}</h2>
                </b-col>
              </b-row>
            </template>
            <div class="col-lg-8 pl-0">
              <div v-html="$t(translationPath + 'description_list_of_income_categories_table')"></div>
            </div>

            <b-table
              class="spirecta-simple-table mb-0"
              show-empty
              hover
              responsive
              striped
              stacked="md"
              :items="tableData"
              :fields="tableColumns"
              :busy="!isLoaded"
              :filter="filter"
              :filter-included-fields="['title','title_with_code', 'type']"
              :tbodyTrClass="trClass"
            >
              <!-- LOADER -->
              <template v-slot:table-busy>
                <loader/>
              </template>

              <!-- FILTER -->
              <template v-slot:top-row>
                <td :colspan="tableColumns.length">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-search text-secondary"></i>
                    <b-form-input v-model="filter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
                  </div>
                </td>
              </template>

              <!-- START CUSTOM SLOTS -->

              <!-- title -->
              <template v-slot:cell(title)="data">
                <template v-if="data.item.row_type === 'subtotal' || data.item.row_type === 'quadrant' || data.item.row_type === 'total'">
                  {{data.value}}
                </template>
                <template v-else-if="data.item.row_type === 'income_category'">
                  <b-link :to="'/reports/performance/accounts/'+data.item.account_id+'/view'">{{data.value}}</b-link>
                </template>
              </template>

              <!-- sum -->
              <template v-slot:cell(sum)="data">
                <template v-if="data.item.row_type !== 'empty' && data.item.row_type !== 'quadrant'">{{data.value}}</template>
              </template>

              <!-- percentage_of_quadrant -->
              <template v-slot:cell(percentage_of_quadrant)="data">
                <template v-if="data.item.row_type !== 'empty' && data.item.row_type !== 'quadrant'">{{data.value}}</template>
              </template>

              <!-- percentage_of_total -->
              <template v-slot:cell(percentage_of_total)="data">
                <template v-if="data.item.row_type !== 'empty' && data.item.row_type !== 'quadrant'">{{data.value}}</template>
              </template>

              <!-- END CUSTOM SLOTS -->
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader'
import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'

export default {
  name: 'CashflowQuadrantListOfAssetsTable',
  components: { Loader },
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      translationPath: 'reports.performance.cashflow_quadrant.table.',
      tableData: [],
      tableColumns: [],
      filter: ''
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    toggleChart (showChart) {
      this.showChart = showChart
      this.$emit('chart-to-display', { from_tab: 'summary', chart: showChart })
    },
    buildTableDataRows () {
      const rows = []
      // const oTotalRow = { title: this.$t('common.sum'), opening_balance: 0, period_change: 0, closing_balance: 0, percentage_of_productive_type: 0, percentage_of_total: 0, row_type: 'total' }
      // const oEmpty = { row_type: 'empty' }
      let fPercentageOfQuadrant = 0
      let fPercentageOfTotal = 0
      let fSum = 0

      rows.push({ row_type: 'empty' })

      for (const sQuadrant in this.apiData.accounts) {
        rows.push({ title: this.$t('common.cashflow_quadrant.' + sQuadrant), row_type: 'quadrant' })
        fPercentageOfQuadrant = 0
        fPercentageOfTotal = 0
        fSum = 0

        for (const asset in this.apiData.accounts[sQuadrant]) {
          rows.push({
            title: this.apiData.accounts[sQuadrant][asset].label,
            group: this.apiData.accounts[sQuadrant][asset].account_group_label,
            account_id: this.apiData.accounts[sQuadrant][asset].account_id,
            sum: this.apiData.accounts[sQuadrant][asset].sum,
            percentage_of_quadrant: this.apiData.accounts[sQuadrant][asset].percentage_of_quadrant,
            percentage_of_total: this.apiData.accounts[sQuadrant][asset].percentage_of_total,
            row_type: 'income_category'
          })
          fPercentageOfQuadrant += this.apiData.accounts[sQuadrant][asset].percentage_of_quadrant
          fPercentageOfTotal += this.apiData.accounts[sQuadrant][asset].percentage_of_total
          fSum += this.apiData.accounts[sQuadrant][asset].sum
        }

        rows.push({
          row_type: 'subtotal',
          title: this.$t('common.partsum'),
          sum: fSum,
          percentage_of_quadrant: fPercentageOfQuadrant + ' %',
          percentage_of_total: fPercentageOfTotal + ' %'
        })
      }

      rows.push({ row_type: 'empty' })

      rows.push({
        row_type: 'total',
        title: this.$t('common.sum').toString(),
        percentage_of_quadrant: '100',
        percentage_of_total: '100',
        sum: this.apiData.totals.amounts.sum
      })

      return rows
    },
    buildTableColumns () {
      const cols = [
        { key: 'title', label: this.$t(this.translationPath + 'th_quadrant') },
        { key: 'group', label: this.$t(this.translationPath + 'th_group') },
        { key: 'sum', label: this.$t('common.sum'), class: 'text-right', formatter: this.amountFormatter },
        { key: 'percentage_of_quadrant', label: this.$t(this.translationPath + 'th_percentage_of_quadrant'), class: 'text-right', formatter: this.percentageFormatter },
        { key: 'percentage_of_total', label: this.$t(this.translationPath + 'th_percentage_of_total'), class: 'text-right', formatter: this.percentageFormatter }
      ]

      return cols
    },
    amountFormatter (value) {
      return this.currentCOA.locale ? formatAmount(value, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 0) : Math.ceil(value)
    },
    percentageFormatter (value) {
      return parseFloat(value).toFixed(1).toString() + ' %'
    },
    trClass (item) {
      if (!item) {
        return ''
      }
      if (Object.prototype.hasOwnProperty.call(item, 'row_type')) {
        return 'tr-' + item.row_type.replace(/_/, '-')
      }

      return ''
    }
  },
  watch: {
    apiData: {
      handler: function () {
        this.tableColumns = this.buildTableColumns()
        this.tableData = this.buildTableDataRows()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-cashflow-quadrant-list-of-assets-table{
  tr.tr-productive-type td:nth-child(1){
    font-weight: bold;
  }

  tr.tr-subtotal td{
    font-weight: bold;
  }

  tr.tr-quadrant td{
    background: white;
    border-top:0;
    font-weight: bold;
    padding-top:20px;
  }
}
</style>
