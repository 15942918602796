<template>
  <b-container fluid class="component-cashflow-quadrant-rader-chart-total">
    <radar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'component-cashflow-quadrant-rader-chart-total-chart'"
      :width="200"
      :height="600"
      :css-classes="''"
      :plugins="[]"
    >
    </radar-chart>
  </b-container>
</template>

<script>
import chartOptions from '@/mixins/defaultChartJsOptions'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import defaultChartPeriodMixin from '@/mixins/defaultChartPeriodMixin'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import RadarChart from '@/components/chartjs/RadarChart'

export default {
  name: 'CashflowQuadrantRadarChartTotal',
  components: { RadarChart },
  mixins: [chartOptions, defaultChartColorPalettes, defaultChartPeriodMixin],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.performance-over-time.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }
      // const labels = []
      const palette = this.getChartPalette()
      this.chartOptions.scales.yAxes[0].display = false
      this.chartOptions.scales.xAxes[0].display = false

      /**
       * The latest version is V3 which just got released, as stated in the migration guide scale option was removed in favor of options.scales.r (or any other scale id, with axis: 'r') the scale option has been removed, you will have to use scales: {r: {config for scale}}
       * https://www.chartjs.org/docs/master/getting-started/v3-migration.html
       * https://stackoverflow.com/questions/67044580/chartjs-radar-chart-options-not-working
       */
      this.chartOptions.scale = {
        ticks: {
          beginAtZero: true,
          max: 100,
          min: 0,
          stepSize: 10
        }
      }

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        return data.datasets[tooltipItem.datasetIndex].label + ': ' + formatNumberToFull(tooltipItem.yLabel) + ' %'
      }

      const aEmployee = [this.apiData.quadrants.employee.total_percentages.sum, 0, 0, this.apiData.quadrants.employee.total_percentages.sum]
      const aSelfEmployed = [0, 0, this.apiData.quadrants.self_employed.total_percentages.sum, this.apiData.quadrants.self_employed.total_percentages.sum]
      const aBusinessOwner = [this.apiData.quadrants.business_owner.total_percentages.sum, this.apiData.quadrants.business_owner.total_percentages.sum, 0, 0]
      const aInvestor = [0, this.apiData.quadrants.investor.total_percentages.sum, this.apiData.quadrants.investor.total_percentages.sum, 0]

      const datasets = [
        { label: this.$t('common.cashflow_quadrant.employee'), data: aEmployee, backgroundColor: palette[0] },
        { label: this.$t('common.cashflow_quadrant.business_owner'), data: aBusinessOwner, backgroundColor: palette[2] },
        { label: this.$t('common.cashflow_quadrant.investor'), data: aInvestor, backgroundColor: palette[3] },
        { label: this.$t('common.cashflow_quadrant.self_employed'), data: aSelfEmployed, backgroundColor: palette[1] }
      ]

      this.chartData = {
        labels: [
          this.$t('common.cashflow_quadrant.employee'),
          this.$t('common.cashflow_quadrant.business_owner'),
          this.$t('common.cashflow_quadrant.investor'),
          this.$t('common.cashflow_quadrant.self_employed')
        ],
        datasets: datasets
      }
    }
  }
}
</script>
