<template>
  <div class="component-reports-performance-cashflow-quadrant">
    <!-- START GRADIENT HEADER -->
    <b-container class="main-gradient light" fluid>

      <!-- START BREADCRUMB, TITLE, TABS WRAPPER -->
      <b-container class="main-gradient-header-wrapper pb-5">

        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters cols="12">
            <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
            <b-col cols="12" sm="4" md="6" lg="7" xl="8" class="pull-left pl-0 pr-0 pr-md-2">
              <h1 class="text-left">{{ $t(translationPath + 'title') }}
                <b-badge v-if="bIsFilterApplied" class="h1badge ml-2" variant="warning">{{$t('reports._common.badge_filter_active')}} <b-link href="#" class="text-black" @click.stop="clearFilter">x</b-link></b-badge>
              </h1>
            </b-col>
            <b-col cols="12" sm="8" md="6" lg="5" xl="4" class="pull-left pr-0 pl-0 pl-md-2">
              <period-selector
                default-period-type="rolling-3-months"
                :show-resolution="true"
                :default-resolution="resolution"
                :show-period-option-full-period="true"
                :allowed-resolutions="['month', 'year']"
                @period-selected="onPeriodChange"
              />
            </b-col>
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper mb-2" no-gutters>
          <b-col no-gutters>
            <cashflow-quadrant-submenu :active="activeTab" @tab-switched="onTabSwitched"></cashflow-quadrant-submenu>
          </b-col>
        </b-row>

      </b-container>
      <!-- END BREADCRUMB, TITLE, TABS WRAPPER -->

      <!-- START CHART WRAPPER -->
      <b-row class="main-gradient-chart-wrapper" :class="{'d-none': activeTab === 'filter'}">
        <!-- Summary tab has different height -->
        <div class="pb-5" style="width:100%;height:700px;" v-if="activeTab === 'summary'">
          <cashflow-quadrant-radar-chart-total  v-if="activeTab === 'summary'"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :isLoaded="!busyState"
            :resolution="resolution"
          />
        </div>
        <!-- Periods / Filter tab has different height -->
        <div class="pb-5" style="width:100%;height:500px;" v-else>
          <cashflow-quadrant-bar-chart-period v-if="activeTab === 'period' && chartToDisplay === 'bar-chart'"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :isLoaded="!busyState"
            :resolution="resolution"
          />

          <cashflow-quadrant-area-chart-period v-if="activeTab === 'period' && chartToDisplay === 'area-chart'"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :isLoaded="!busyState"
            :resolution="resolution"
          />

          <cashflow-quadrant-area-percentage-chart-period v-if="activeTab === 'period' && chartToDisplay === 'area-percentage-chart'"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :isLoaded="!busyState"
            :resolution="resolution"
          />

          <cashflow-quadrant-multi-series-pie-chart v-if="activeTab === 'list'"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :isLoaded="!busyState"
            :resolution="resolution"
          />
        </div>
      </b-row>
      <!-- END CHART WRAPPER -->

    </b-container>
    <!-- END GRADIENT HEADER -->

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper pt-0 transaction-view-wrapper">
      <!-- Table components here -->
      <cashflow-quadrant-total-table v-if="activeTab === 'summary'"
        :is-loaded="!busyState"
        :api-data="apiData"
        :start-date="startDate"
        :end-date="endDate"
      />

      <cashflow-quadrant-table-period v-if="activeTab === 'period'"
        :is-loaded="!busyState"
        :api-data="apiData"
        :start-date="startDate"
        :end-date="endDate"
        :resolution="resolution"
        :active-chart-option="chartToDisplay"
        @chart-to-display="(data) => chartToDisplay = data.chart"
      />

      <cashflow-quadrant-list-of-accounts-table v-if="activeTab === 'list'"
        :is-loaded="!busyState"
        :api-data="apiData"
        :start-date="startDate"
        :end-date="endDate"
        :resolution="resolution"
        :active-chart-option="chartToDisplay"
        @chart-to-display="(data) => chartToDisplay = data.chart"
      />

      <report-filter
        v-if="activeTab === 'filter'"
        ref="ReportFilter"
        :filter-data="reportFilterData"
        :show-toast-on-filter-apply="false"
        :disabled="['expense']"
        @filter-applied="onFilterApplied"
        @filter-data-updated="mergeFilterPartials"
        @current-filter-deleted="onFilterDeleted"
      />

    </b-container>
    <!-- END PAGE WRAPPER -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import titleMixins from '@/mixins/title'
import PeriodSelector from '@/components/common/PeriodSelector'
import CashflowQuadrantSubmenu from './inc/CashflowQuadrantSubmenu'
import CashflowQuadrantTablePeriod from './tables/CashflowQuadrantTablePeriod'
import CashflowQuadrantBarChartPeriod from './charts/CashflowQuadrantBarChartPeriod'
import CashflowQuadrantAreaChartPeriod from './charts/CashflowQuadrantAreaChartPeriod'
import CashflowQuadrantAreaPercentageChartPeriod from './charts/CashflowQuadrantAreaPercentageChartPeriod'
import CashflowQuadrantRadarChartTotal from './charts/CashflowQuadrantRadarChartTotal'
import CashflowQuadrantTotalTable from './tables/CashflowQuadrantTotalTable'
import CashflowQuadrantListOfAccountsTable from './tables/CashflowQuadrantListOfAccountsTable'
import CashflowQuadrantMultiSeriesPieChart from './charts/CashflowQuadrantMultiSeriesPieChart'
import ReportFilter from '@/components/common/ReportFilter.vue'
import axios from 'axios'

export default {
  name: 'CashflowQuadrantPerformanceReport',
  mixins: [titleMixins],
  components: {
    CashflowQuadrantRadarChartTotal,
    CashflowQuadrantAreaPercentageChartPeriod,
    CashflowQuadrantSubmenu,
    PeriodSelector,
    CashflowQuadrantTablePeriod,
    CashflowQuadrantBarChartPeriod,
    CashflowQuadrantAreaChartPeriod,
    CashflowQuadrantTotalTable,
    CashflowQuadrantListOfAccountsTable,
    CashflowQuadrantMultiSeriesPieChart,
    ReportFilter
  },
  data () {
    return {
      validRouteParams: ['summary', 'period', 'filter', 'list'],
      activeTab: 'summary',
      apiData: null,
      bHasAPIDataBeenFetchedOnce: false,
      translationPath: 'reports.performance.cashflow_quadrant.',
      busyState: true,
      startDate: null,
      endDate: null,
      resolution: 'month',
      chartToDisplay: 'bar-chart',
      reportFilterData: null,
      bIsFilterApplied: false
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    breadcrumbItems () {
      let aBreadcrumb = []

      aBreadcrumb = [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports/performance' },
        { text: this.$t('reports._common.performance_reports'), to: '/reports/performance/' },
        { text: '', active: true }
      ]

      return aBreadcrumb
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title')
    },
    currency () {
      return this.currentCOA.currency_iso ? this.currentCOA.currency_iso : ''
    },
    currencySymbol () {
      return this.currentCOA.currency ? this.currentCOA.currency : ''
    }
  },
  methods: {
    /**
     * Handle emitted data from period_selector
     */
    onPeriodChange (data) {
      this.startDate = data.sDateStart
      this.endDate = data.sDateEnd
      this.resolution = data.resolution

      this.loadData()
    },
    onTabSwitched (newtab) {
      this.activeTab = newtab
      if (newtab !== this.$route.params.tab && this.validRouteParams.indexOf(newtab) !== -1) {
        this.$router.push({ params: { tab: newtab } })
      } else if (newtab !== this.$route.params.tab && this.validRouteParams.indexOf(newtab) === -1) {
        this.$router.push({ params: { tab: 'summary' } })
      }
    },
    async loadData () {
      return new Promise((resolve, reject) => {
        this.busyState = true

        let sApiEndpoint = `${process.env.VUE_APP_ROOT_API}/reports/performance/cashflow-quadrant?start_date=${this.startDate}&end_date=${this.endDate}&resolution=${this.resolution}`
        // Filter accounts
        const aIncludeAccounts = []
        if (this.reportFilterData && Object.prototype.hasOwnProperty.call(this.reportFilterData, 'oFormSelectIncome')) {
          this.reportFilterData.oFormSelectIncome.map(a => { aIncludeAccounts.push(a) })
        }
        if (aIncludeAccounts.length) {
          sApiEndpoint += '&filter_accounts_income=' + aIncludeAccounts.join(',')
        }

        // Filter persons
        if (this.reportFilterData && Object.prototype.hasOwnProperty.call(this.reportFilterData, 'oFormSelectPerson')) {
          sApiEndpoint += '&filter_persons=' + this.reportFilterData.oFormSelectPerson.join(',')
        }

        axios.get(sApiEndpoint)
          .then(response => {
            this.apiData = response.data.data
            this.busyState = false
            this.bHasAPIDataBeenFetchedOnce = true
            resolve(response.data.data)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    onFilterApplied (eventData) {
      this.bIsFilterApplied = true
      this.loadData()
        .then(() => {
          this.$bvToast.toast(this.$t('common.filter_settings_description').toString(), {
            title: this.$t('common.filter_settings_title').toString(),
            variant: 'success',
            solid: true
          })
        })
    },
    onFilterDeleted () {
      this.bIsFilterApplied = false
      this.loadData()
    },
    mergeFilterPartials (partialData) {
      if (!this.reportFilterData) {
        this.reportFilterData = null
      }
      this.reportFilterData = Object.assign({}, this.reportFilterData, partialData)
    },
    clearFilter () {
      this.bIsFilterApplied = false
      if (this.$refs.ReportFilter) {
        this.$refs.ReportFilter.reset()
      } else {
        this.reportFilterData.oFormSelectIncome = []
        this.reportFilterData.oFormSelectExpense = []
        this.reportFilterData.oFormSelectAsset = []
        this.reportFilterData.oFormSelectLiability = []
        this.reportFilterData.oFormSelectPerson = []
        this.reportFilterData.iFormSelectFilter = 0
        this.reportFilterData.oFilterLoaded = null
        this.reportFilterData.oFormFilterTitle = ''
        this.reportFilterData.oFormFilterDescription = ''
      }
      this.loadData()
        .then(() => {
          this.$bvToast.toast(this.$t('common.filter_settings_description').toString(), {
            title: this.$t('common.filter_settings_title').toString(),
            variant: 'success',
            solid: true
          })
        })
    }
  },
  mounted () {
    if (this.validRouteParams.indexOf(this.$route.params.tab) !== -1) {
      this.activeTab = this.$route.params.tab
    } else {
      this.$router.push({ params: { tab: 'summary' } })
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-reports-performance-cashflow-quadrant {
  width: 100%;

  table {
    td {
      font-size: 100%;
    }

    /* Rows */
    tr.tr-account-group {
      td{
        background: white;
        border-top: 0;
        padding-top: 15px;
        border-bottom: 1px solid #949191;
        font-size: 90%;
      }
      td:first-child {
        font-size: 110%;
      }
    }

    tr.tr-account {
      td:first-child {
        font-weight: 400
      }
    }

    tr.tr-part-sum td {
      font-weight: 900;
      border-top: 1px solid #949191;
      border-bottom: 1px solid #949191;
    }

    tr.tr-empty-row td {
      background: white;
    }

    tr.tr-total{
      td {
        font-weight: 900;
        border-top: 1px solid black;
        border-bottom: 2px solid black;
      }
    }

    tr.tr-total-acc {
      td {
        border-bottom: 2px solid black;
      }
    }

    /* Columns */
    td.td-total-column {
      border-left: 1px solid black;
    }
  }
}
</style>
