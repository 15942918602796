<template>
  <b-container fluid class="component-cashflow-quadrant-area-percentage-chart-period">
    <line-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'component-cashflow-quadrant-area-percentage-chart-period-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </line-chart>
  </b-container>
</template>

<script>
import LineChart from '@/components/chartjs/LineChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import defaultChartPeriodMixin from '@/mixins/defaultChartPeriodMixin'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'CashflowQuadrantAreaPercentageChartPeriod',
  components: { LineChart },
  mixins: [chartOptions, defaultChartColorPalettes, defaultChartPeriodMixin],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.performance-over-time.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }
      const labels = []

      this.chartOptions.scales.yAxes[0].stacked = true
      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return value + ' %'
      }

      this.apiData.meta.periods.map(period => {
        labels.push(this.getTimeLabelFromPeriodLabel(period, this.resolution))
      })

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        return data.datasets[tooltipItem.datasetIndex].label + ': ' + formatNumberToFull(tooltipItem.yLabel) + ' %'
      }

      const datasets = []
      const palette = this.getChartPalette()
      for (const quadrant in this.apiData.quadrants) {
        const dataset = {
          backgroundColor: palette.shift(),
          type: 'line',
          label: this.$t('common.cashflow_quadrant.' + quadrant),
          data: []
        }
        for (const period in this.apiData.quadrants[quadrant].period_percentages) {
          dataset.data.push(this.apiData.quadrants[quadrant].period_percentages[period])
        }
        datasets.push(dataset)
      }

      this.chartData = {
        labels: labels,
        datasets: datasets
      }
    }
  }
}
</script>
