<template>
  <div class="component-cashflow-quadrant-toggle-columns">
      <b-dropdown right
                  :text="$t('common.toggle_columns')"
                  variant='outline-secondary'
                  class="float-right text-regular mr-3 toggleColumnWrapper"
                  size="sm"
      >
        <b-dropdown-item @click="toggleColumns('total')" v-bind:class="{ active: options.total }">{{$t('common.total')}}</b-dropdown-item>
        <b-dropdown-item @click="toggleColumns('average')" v-bind:class="{ active: options.average }">{{$t('common.average')}}</b-dropdown-item>
        <b-dropdown-item @click="toggleColumns('median')" v-bind:class="{ active: options.median }">{{$t('common.median')}}</b-dropdown-item>
        <b-dropdown-item @click="toggleColumns('min')" v-bind:class="{ active: options.min }">{{$t('common.min')}}</b-dropdown-item>
        <b-dropdown-item @click="toggleColumns('max')" v-bind:class="{ active: options.max }">{{$t('common.max')}}</b-dropdown-item>
      </b-dropdown>

  </div>
</template>

<script>
export default {
  name: 'CashflowQuadrantToggleColumns',
  props: {
    options: {
      type: Object,
      default: function () {
        return {
          total: true,
          average: true,
          median: false,
          min: false,
          max: false
        }
      }
    }
  },
  data () {
    return {
      translationPath: ''
    }
  },
  methods: {
    toggleColumns (columnToDisplay) {
      this.$emit('column-to-toggle-emit', columnToDisplay)
    }
  }
}
</script>

<style lang="scss">
.component-cashflow-quadrant-toggle-columns {
  li a { color: black; }
  li.active a:after {
    content: " ✓";
  }
  li.active a { font-weight: 900; }
}
</style>
